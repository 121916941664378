
import { Options, mixins } from "vue-class-component";
import StoreReports from "../../service/StoreReports";
import UtilityOptions from "../../mixins/UtilityOptions";

@Options({
  components: {},
})

export default class ItemsReport extends mixins(UtilityOptions) {
  private lists  = [];
  private reportService;
  private counter = 0;
  private resultTitle = "";
  private productDialog = false;
  private loading = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Report", to: "report" },
    { label: "Items Report" },
  ];

  private searchAssociate = {
    id: "",
    dateFrom: "",
    dateTo: "",
    filter: "None",
    filterBranch: 0,
  };

  private filterDates = [];
  private dialogTitle;
  private filterBranch:any = [];

  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.reportService = new StoreReports();
  }
  
   //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.storeList();
    this.loadList();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {       
    this.dialogTitle = "Filter Items";
    this.productDialog = true;
  }

  //USED TO LOAD SEARCH DIALOG BOX DATA
  storeList() {
    this.reportService.storeList().then((res) => {
      const data = this.camelizeKeys(res);
      this.filterBranch = data.branchesList;
      this.filterDates = data.filterList;
    });
  }
 
  // USED TO GET SEARCHED ASSOCIATE
  loadList() {
    this.loading         = true;
    this.reportService.itemsReport(this.searchAssociate).then((res) => {
      const data       = this.camelizeKeys(res);
      this.resultTitle = data.resultTitle;
      this.lists       = data.record;
      console.log(this.lists);
      this.loading     = false;
    });
    this.productDialog   = false;
  }

  calculateQty(data)
  {
    return this.formatAmount(data.qty * data.itemQty);
  }
}
