<template>
  <section>
    <div class="app-container">
      <Toolbar>
        <template v-slot:left>
          <Breadcrumb
            :home="home"
            :model="items"
            class="p-menuitem-text p-p-1"
          />
        </template>

        <template v-slot:right>
          <div class="p-mx-2">
            <Button
              icon="pi pi-search"
              class="p-button-success px-4"
              @click="openDialog"
            />
          </div>
          <div class="">
            <Button
              icon="pi pi-print"
              class="p-button-primary px-4"
              @click="openDialog"
            />
          </div>
        </template>
      </Toolbar>
      <div class="m-2 mt-4 mb-4 p-text-center">
        <h5>Employee Measurable & Associate Report</h5>
        <p>{{ resultTitle }}</p>
      </div>
      <div class="p-mt-2">
        <DataTable
          :value="lists"
          :lazy="true"
          :scrollable="true"
          class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        >
          <template #empty>
            <div class="p-text-center p-p-3">No records found</div>
          </template>
          <Column
            field="empId"
            header="Emp ID"
            headerStyle="width: 130px"
          ></Column>
          <Column
            field="associateName"
            header="Associate Name"
            headerStyle="width: 200px"
          ></Column>
          <Column
            field="jobTitle"
            header="Job Title"
            headerStyle="width: 180px"
          ></Column>
          <Column
            field="store"
            header="Store"
            headerStyle="width: 250px"
          ></Column>
          <Column header="Pay Rate" headerStyle="width: 100px">
            <template #body="slotProps">
              $ {{ formatAmount(slotProps.data.payRate) }}
            </template>
          </Column>
          <Column header="Hire Date" headerStyle="width: 100px">
            <template #body="slotProps">
              {{ slotProps.data.hireDate }}
            </template>
          </Column>
          <Column
            field="yos"
            header="Years of Service"
            headerStyle="width: 150px"
          ></Column>
          <Column
            field="positionStatus"
            header="Position Status"
            headerStyle="width: 150px"
          ></Column>
          <Column header="Total Production" headerStyle="width: 150px">
            <template #body="slotProps">
              $ {{ formatAmount(slotProps.data.totalProduction) }}
            </template>
          </Column>
          <Column header="Average alteration" headerStyle="width: 180px">
            <template #body="slotProps">
              $ {{ formatAmount(slotProps.data.avgAlteration) }}
            </template>
          </Column>
          <Column header="Hours Worked" headerStyle="width: 150px">
            <template #body="slotProps">
              {{ formatAmount(slotProps.data.hoursWorked) }} Hrs
            </template>
          </Column>
          <Column header="Individual Magic" headerStyle="width: 150px">
            <template #body="slotProps">
              $ {{ formatAmount(slotProps.data.individualMagic) }}
            </template>
          </Column>
          <Column header="Comparable Magic" headerStyle="width: 180px">
            <template #body="slotProps">
              $ {{ formatAmount(slotProps.data.comparableMagic) }}
            </template>
          </Column>
          <Column header="Invoice Entered" headerStyle="width: 150px">
            <template #body="slotProps">
              {{ formatAmount(slotProps.data.invoiceEntered) }}
            </template>
          </Column>
          <Column header="Sale Invoices Entered" headerStyle="width: 180px">
            <template #body="slotProps">
              $ {{ formatAmount(slotProps.data.saleInvoicesEntered) }}
            </template>
          </Column>
          <Column header="Average Sale" headerStyle="width: 150px">
            <template #body="slotProps">
              $ {{ formatAmount(slotProps.data.averageSale) }}
            </template>
          </Column>
          <Column header="Super crease QTY" headerStyle="width: 150px">
            <template #body="slotProps">
              {{ formatAmount(slotProps.data.superCreaseQty) }}
            </template>
          </Column>
          <Column header="Super crease Value" headerStyle="width: 150px">
            <template #body="slotProps">
              $ {{ formatAmount(slotProps.data.superCreaseValue) }}
            </template>
          </Column>
          <Column header="Product Sales Value" headerStyle="width: 200px">
            <template #body="slotProps">
              $ {{ formatAmount(slotProps.data.productSalesValue) }}
            </template>
          </Column>
          <Column header="Avg Product" headerStyle="width: 150px">
            <template #body="slotProps">
              $ {{ formatAmount(slotProps.data.avgProduct) }}
            </template>
          </Column>
          <Column header="12 month avg alteration" headerStyle="width: 200px">
            <template #body="slotProps">
              $ {{ formatAmount(slotProps.data.twMonthAvgAlteration) }}
            </template>
          </Column>
          <Column
            header="12 month average individual magic"
            headerStyle="width: 300px"
          >
            <template #body="slotProps">
              $ {{ formatAmount(slotProps.data.twMonthAverageIndividualMagic) }}
            </template>
          </Column>
          <Column
            header="12 month average comparable magic"
            headerStyle="width: 300px"
          >
            <template #body="slotProps">
              $ {{ formatAmount(slotProps.data.twMonthAverageComparableMagic) }}
            </template>
          </Column>
          <Column header="12 month average sale" headerStyle="width: 200px">
            <template #body="slotProps">
              $ {{ formatAmount(slotProps.data.twMonthAverageSale) }}
            </template>
          </Column>
          <Column
            header="12 month average of product sales"
            headerStyle="width: 300px"
          >
            <template #body="slotProps">
              $ {{ formatAmount(slotProps.data.twMonthAverageOfProductSales) }}
            </template>
          </Column>
          <Column header="12 month production" headerStyle="width: 250px">
            <template #body="slotProps">
              $ {{ formatAmount(slotProps.data.twMonthProductionDollar) }}
            </template>
          </Column>
          <Column header="12 month hours worked" headerStyle="width: 200px">
            <template #body="slotProps">
              $ {{ formatAmount(slotProps.data.twMonthHoursWorked) }}
            </template>
          </Column>
          <Column header="12 month sales" headerStyle="width: 200px">
            <template #body="slotProps">
              $ {{ formatAmount(slotProps.data.twMonthSales) }}
            </template>
          </Column>
        </DataTable>
      </div>

      <Dialog
        v-model:visible="productDialog"
        :style="{ width: '50vw' }"
        :maximizable="true"
        position="top"
        class="p-fluid"
      >
        <template #header>
          <h5 class="p-dialog-titlebar p-dialog-titlebar-icon">
            <i class="pi pi-search" style="font-size:1.2rem;"></i>
            {{ dialogTitle }}
          </h5>
        </template>
        <div class="p-field">
          <label for="name">Employee ID:</label>
          <InputText
            id="name"
            v-model="searchAssociate.id"
            required="true"
            :useGrouping="false"
            autofocus
          />
        </div>
        <div class="p-field">
          <label for="filterStore">Filter Date:</label>
          <Dropdown
            v-model="searchAssociate.filter"
            :options="filterDates"
            :filter="true"
            optionLabel="key"
            optionValue="value"
          />
        </div>
        <h5>OR</h5>
        <div class="p-field">
          <label for="from">Date From:</label>
          <input
            type="date"
            id="from"
            v-model="searchAssociate.dateFrom"
            class="form-control"
          />
        </div>
        <div class="p-field">
          <label for="to">Date To:</label>
          <input
            type="date"
            id="to"
            v-model="searchAssociate.dateTo"
            class="form-control"
          />
        </div>
        <div class="p-field p-pt-5">
          <label for="filterStore">Filter Branch:</label>
          <Dropdown
            v-model="searchAssociate.filterBranch"
            :options="filterBranch"
            :filter="true"
            optionLabel="mall"
            optionValue="code"
          />
        </div>
        <div class="p-field">
          <label for="province" class="d-block">Employee Filter:</label>
          <Dropdown
            v-model="searchAssociate.empFilter"
            :options="employeeFilter"
            optionLabel="name"
            optionValue="value"
          />
        </div>
        <template #footer>
          <Button
            type="submit"
            label="Search"
            icon="pi pi-search"
            class="p-button-primary"
            @click="loadList"
          />
        </template>
      </Dialog>
    </div>
  </section>
</template>
<script lang="ts">
import { Options, mixins } from "vue-class-component";
import StoreReports from "../../service/StoreReports";
import UtilityOptions from "../../mixins/UtilityOptions";

@Options({
  components: {},
})
export default class AssociateReport extends mixins(UtilityOptions) {
  private lists = [];
  private reportService;
  private resultTitle = "";
  private productDialog = false;
  private loading = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Report", to: "report" },
    { label: "Associate Report" },
  ];

  private searchAssociate = {
    id: "",
    dateFrom: "",
    dateTo: "",
    filter: "None",
    filterBranch: 0,
    empFilter: "current",
  };

  private filterDates = [];

  private dialogTitle;
  private submitted = false;
  private employeeFilter = [
    {  name: "Only Current Store Associates", value:"current" },
    {  name: "Only Current & Associated Store Associates",value: 'associated' },
    {  name: "All Associates",value: 'all' },
  ];

  private filterBranch = [];

  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.reportService = new StoreReports();
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.storeList();
    this.loadList();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.submitted = false;
    this.dialogTitle = "Filter Employee Measurable Report";
    this.productDialog = true;
  }

  //USED TO LOAD SEARCH DIALOG BOX DATA
  storeList() {
    this.reportService.storeList().then((res) => {
      const data = this.camelizeKeys(res);
      this.filterBranch = data.branchesList;
      this.filterDates = data.filterList;
    });
  }

  // USED TO GET SEARCHED ASSOCIATE
  loadList() {
    this.loading = true;
    this.reportService.associateReport(this.searchAssociate).then((res) => {
      const data = this.camelizeKeys(res);
      this.resultTitle = data.resultTitle;
      this.lists = data.records;
      this.loading = false;
    });
    this.productDialog = false;
  }
}
</script>
