<template>
  <section>
    <div class="app-container">
      <Toolbar>
        <template v-slot:left>
          <Breadcrumb
            :home="home"
            :model="items"
            class="p-menuitem-text p-p-1"
          />
        </template>

        <template v-slot:right>
          <div class="p-mx-2">
            <Button
              icon="pi pi-search"
              class="p-button-success px-4"
              @click="openDialog"
            />
          </div>
          <div class="">
            <Button
              icon="pi pi-print"
              class="p-button-primary px-4"
              @click="openDialog"
            />
          </div>
        </template>
      </Toolbar>
      <div class="m-2 mt-4 mb-4 p-text-center">
          <h5>Flash Report {{getActiveRegion}}</h5>
          <p>{{resultTitle}}</p>
      </div>
      <div class="p-mt-2">
        <div class="table-responsive">
          <table class="table table-bordered table-striped table-hover mb-0 text-nowrap">
                <tr style="background-color:yellow">
                  <th></th>
                  <th></th>
                  <th class="text-center" colspan="15">Sales</th>
                  <th class="text-center" colspan="2" >Profitability</th>
                  <th class="text-center" colspan="5" >Service</th>
                  <th class="text-center" colspan="3" >Quality</th>
                  <th class="text-center" colspan="2" >Turnaround</th>
                  <th></th>
                  <th class="text-center" colspan="2">Pricing</th>
                </tr>
                <tr style="background-color:orange">
                  <th></th>
                  <th></th>
                  <th class="text-center" colspan="2">Regular</th>
                  <th class="text-center" colspan="2">Referral</th>
                  <th class="text-center" colspan="2" >Corporate</th>
                  <th class="text-center" colspan="2">Products</th>
                  <th class="text-center" colspan="2">Dry Cleaning</th>
                  <th class="text-center" colspan="5">Total</th>
                  <th>Combined Magic</th>
                  <th>Production</th>
                  <th class="text-center" colspan="2" >GPS</th>
                  <th class="text-center" colspan="3">Invoices</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>RA over</th>
                  <th>SA over</th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
                <tr style="background-color:#004C97; color:#fff;">
                  <th>Store</th>
                  <th>Mall</th>
                  <th>%</th>
                  <th>Actual</th>
                  <th>%</th>
                  <th>Actual</th>
                  <th>%</th>
                  <th>Actual</th>
                  <th>%</th>
                  <th>Actual</th>
                  <th>%</th>
                  <th>Actual</th>
                  <th>Plan</th>
                  <th>Actual</th>
                  <th>Diff</th>
                  <th>LY</th>
                  <th>LY +/- %</th>
                  <th></th>
                  <th></th>
                  <th>Score</th>
                  <th>% of</th>
                  <th>TY</th>
                  <th>LY</th>
                  <th>+/-</th>
                  <th>Redo</th>
                  <th>Refund</th>
                  <th>BB</th>
                  <th>3 hrs</th>
                  <th>12 hrs</th>
                  <th>OnTime</th>
                  <th>AvgSales</th>
                  <th>Disc</th>
                </tr>
                <tr v-for="s in flashReport" :key="s">
                  <td>{{s.branchCode}}</td>
                  <td>{{s.branchName}}</td>
                  <td>{{formatAmount(s.salesRegularPer)}}%</td>
                  <td>${{formatAmount(s.salesRegularActual)}}</td>
                  <td>{{formatAmount(s.referralPer)}}%</td>
                  <td>${{formatAmount(s.referralActual)}}</td>
                  <td>{{formatAmount(s.corporatePer)}}%</td>
                  <td>${{formatAmount(s.corporateActual)}}</td>
                  <td>{{formatAmount(s.productPer)}}%</td>
                  <td>${{formatAmount(s.productActual)}}</td>
                  <td>{{formatAmount(s.dryCleaningPer)}}%</td>
                  <td>${{formatAmount(s.dryCleaningPer)}}</td>
                  <td></td>
                  <td>${{formatAmount(s.totalActual)}}</td>
                  <td></td>
                  <td>{{formatAmount(s.ly)}}</td>
                  <td>{{formatAmount(getLyPercentage(s.totalActual,s.ly))}}%</td>
                  <td>{{formatAmount(combinedMagic(s.totalActual,s.totalHrs))}}%</td>
                  <td>${{formatAmount(s.production)}}</td>
                  <td>{{formatAmount(s.gpsScore)}}</td>
                  <td>{{formatAmount(s.gpsPer)}}</td>
                  <td>{{s.invTy}}x</td>
                  <td>{{s.invLy}}x</td>
                  <td>{{s.invPlusMinus}}x</td>
                  <td>{{s.redo}}x</td>
                  <td>{{s.refund}}x</td>
                  <td>{{s.buyback}}x</td>
                  <td>{{s.turnaround3Hrs}}x</td>
                  <td>{{s.turnaround12Hrs}}x</td>
                  <td>{{s.turnaroundOnTime}}x</td>
                  <td>{{formatAmount(s.pricingAvgSales)}}%</td>
                  <td>${{formatAmount(s.pricingDisc)}}</td>
                </tr>
                 <tr style="background-color:orange;">
                  <td>Summary</td>
                  <td>Total</td>
                  <td>{{formatAmount(getPercentage('Regular'))}}%</td>
                  <td>${{formatAmount(getSalesAmt('Regular'))}}</td>
                  <td>{{formatAmount(getPercentage('Referral'))}}%</td>
                  <td>${{formatAmount(getSalesAmt('Referral'))}}</td>
                  <td>{{formatAmount(getPercentage('Corporate'))}}%</td>
                  <td>${{formatAmount(getSalesAmt('Corporate'))}}</td>
                  <td>{{formatAmount(getPercentage('Product'))}}%</td>
                  <td>${{formatAmount(getSalesAmt('Product'))}}</td>
                  <td>{{formatAmount(getPercentage('Dry_clean'))}}%</td>
                  <td>${{formatAmount(getSalesAmt('Dry_clean'))}}</td>
                  <td></td>
                  <td>${{formatAmount(getTotalActual)}}</td>
                  <td></td>
                  <td>${{formatAmount(getSalesAmt('ly'))}}</td>
                  <td>{{formatAmount(getLyPercentage(getTotalActual,getSalesAmt('ly')))}}%</td>
                  <td>{{formatAmount(totalCombinedMagic)}}%</td>
                  <td>${{formatAmount(getSalesAmt('production'))}}</td>
                  <td></td>
                  <td></td>
                  <td>{{getSalesAmt('invTy')}}x</td>
                  <td>{{getSalesAmt('invLy')}}x</td>
                  <td>{{getSalesAmt('invTy')-getSalesAmt('invLy')}}x</td>
                  <td>{{getSalesAmt('redo')}}x</td>
                  <td>{{getSalesAmt('refund')}}x</td>
                  <td>{{getSalesAmt('bb')}}x</td>
                  <td>{{getSalesAmt('3hrs')}}x</td>
                  <td>{{getSalesAmt('12hrs')}}x</td>
                  <td>{{getSalesAmt('OnTime')}}x</td>
                  <td>{{formatAmount( getTotalAvgSales)}}%</td>
                  <td>${{formatAmount(getSalesAmt('disc'))}}</td>
                </tr>
        </table>
        </div>
      </div>
      <Dialog
        v-model:visible="productDialog"
        :style="{ width: '50vw' }"
        :maximizable="true"
        position="top"
        class="p-fluid"
      >
        <template #header>
          <h5 class="p-dialog-titlebar p-dialog-titlebar-icon">
           <i class="pi pi-search" style="font-size:1.2rem;"></i> {{ dialogTitle }}
          </h5>
        </template>
        <div class="p-field">
            <label for="filterStore">Filter Date:</label>
            <Dropdown
              v-model="searchAssociate.filter"
              :options="filterDates"
              :filter="true"
              optionLabel="key"
              optionValue="value"
            />
        </div>
        <h5>OR</h5>
        <div class="p-field">
          <label for="from">Date From:</label>
          <input type="date" id="from"  v-model="searchAssociate.dateFrom" class="form-control">
        </div>
        <div class="p-field">
          <label for="to">Date To:</label>
          <input type="date" id="to"  v-model="searchAssociate.dateTo" class="form-control">
        </div>
        <div class="p-field p-pt-5">
            <label for="filterStore">Filter Region:</label>
            <Dropdown
                v-model="searchAssociate.filterRegion"
                :options="filterRegions"
                :filter="true"
                optionLabel="regionName"
                optionValue="regionId"
            />
            
        </div>
        <template #footer>
          <Button
            type="submit"
            label="Search"
            icon="pi pi-search"
            class="p-button-primary"
            @click="loadList"
          />
        </template>
      </Dialog>
    </div>
  </section>
</template>
<script lang="ts">
import { Options, mixins } from "vue-class-component";
import StoreReports from "../../service/StoreReports";
import UtilityOptions from "../../mixins/UtilityOptions";

interface IRegions {
  branchId: string;
  country: string;
  createdDate: string;
  provinceName: string;
  regionCode: string;
  regionId: string;
  regionName: string;
}

interface FlastReport {
  branchCode: string;
  branchName: string;
  buyback: number;
  corporateActual: number;
  corporatePer: number;
  diffPer: number;
  dryCleaningActual: number;
  dryCleaningPer: number;
  gpsPer: number;
  gpsScore: number;
  invLy: number;
  invPlusMinus: number;
  invTy: number;
  ly: number;
  lyPer: number;
  totalHrs: number;
  production: number;
  pricingAvgSales: number;
  pricingDisc: number;
  productActual: number;
  productPer: number;
  redo: number;
  referralActual: number;
  referralPer: number;
  refund: number;
  salesRegularActual: number;
  salesRegularPer: number;
  serviceDate: string;
  totalActual: number;
  turnaround3Hrs: number;
  turnaround12Hrs: number;
  turnaroundOnTime: number;
  yearPlanned: [];
}


@Options({
  components: {},
})

export default class FlashReport extends mixins(UtilityOptions) {

  private lists: FlastReport [] = [];
  private reportService;
  private resultTitle = "";
  private weekStart = 0;
  private weekEnd = 0;
  private productDialog = false;
  private loading = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Report", to: "report" },
    { label: "Flash Report" },
  ];

  private filterDates = [
    {
      key: 'This Week',
      value: 'this_week'
    },
    {
      key: 'Month to Date',
      value: 'month_to_date'
    },
    {
      key: 'Year to Date',
      value: 'year_to_date'
    }
  ];
  private dialogTitle;
  private submitted = false;
  private filterRegions: IRegions [] = [];

  private searchAssociate = {
    id: "",
    dateFrom: "",
    dateTo: "",
    filter: "this_week",
    filterRegion: '',
  };

  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.reportService = new StoreReports();
  }
  
   //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.storeList();
    this.loadList();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {       
    this.submitted = false;
    this.dialogTitle = "Filter invoices";
    this.productDialog = true;
  }

  //USED TO LOAD SEARCH DIALOG BOX DATA
  storeList() {
    this.reportService.storeList().then((res) => {
      const data = this.camelizeKeys(res);
      this.filterRegions = data.regionList;
      this.searchAssociate.filterRegion = this.getDefaultRegion;
    });
  }
 
  // USED TO GET SEARCHED ASSOCIATE
  loadList() {
    this.loading = true;
    this.reportService.flashReport(this.searchAssociate).then((res) => {
        const data = this.camelizeKeys(res);
        this.resultTitle = data.resultTitle;
        this.lists = data.records;
        this.weekStart = data.weekStart;
        this.weekEnd   = data.weekEnd;
        this.loading = false;
      });
    this.productDialog = false;
  }

  get getActiveRegion()
  {
    let regionName = '';
    const r = this.filterRegions.filter(e => e.regionId == this.searchAssociate.filterRegion);

    if(r.length > 0)
    {
      regionName = r[0].regionName+' ('+r[0].regionCode+')';
    }

    return regionName;
  }

  get getDefaultRegion()
  {
    let id = '';
    if(this.filterRegions.length > 0)
    {
      id = this.filterRegions[0].regionId;
    }
    return id;
  }

  get flashReport()
  {
    // const s1 = Number(this.weekStart);
    // const s2 = Number(this.weekEnd);

    // const a = this.lists.forEach(e => {
    // let totalPlanned = 0;

      
      
    //   if(e.yearPlanned.length >= 54)
    //   {
    //     for (let i = 1; i < 54; i++) {
    //         if(i >= s1 && i <= s2)
    //         {
    //           totalPlanned = totalPlanned + Number(e.yearPlanned[i]); 
    //         } 
    //     }
    //   }
      


    //   // for(let i = s1; i <= s2; i++)
    //   // {
    //   //   const n = 'week'+i;
    //   //   totalPlanned = totalPlanned + e.yearPlanned.'week';
    //   //   console.log(e);
    //   //   console.log(i);    
    //   // }
        
    // });
    
    return this.lists;
  }

  getLyPercentage(actual,ly)
  {
    let p = 0;

    if(ly > 0)
    {
      p = ((actual/ly) * 100 ) - 100;  
    }
    return p;
  }

  getPercentage(type)
  {
    let p = 0;

    const total = this.getSalesAmt(type);
    const actual = this.getTotalActual;

    if(total > 0)
    {
      p = (Number(total/actual)) * 100;
    }

    return p;
  }
  
  getSalesAmt(type)
  {
    let total = 0;

    if(type == 'Regular')
    {
      this.lists.forEach(e => {
        total = total + e.salesRegularActual;
      });
    }
    else if(type == 'Referral')
    {
      this.lists.forEach(e => {
        total = total + e.referralActual;
      });
    }
    else if(type == 'Corporate')
    {
      this.lists.forEach(e => {
        total = total + e.corporateActual;
      });
    }
    else if(type == 'Product')
    {
      this.lists.forEach(e => {
        total = total + e.productActual;
      });
    }
    else if(type == 'Dry_clean')
    {
      this.lists.forEach(e => {
        total = total + e.dryCleaningActual;
      });
    }
    else if(type == '3hrs')
    {
      this.lists.forEach(e => {
        total = total + e.turnaround3Hrs;
      });
    }
    else if(type == '12hrs')
    {
      this.lists.forEach(e => {
        total = total + e.turnaround12Hrs;
      });
    }
    else if(type == 'OnTime')
    {
      this.lists.forEach(e => {
        total = total + e.turnaroundOnTime;
      });
    }
    else if(type == 'redo')
    {
      this.lists.forEach(e => {
        total = total + e.redo;
      });
    }
    else if(type == 'refund')
    {
      this.lists.forEach(e => {
        total = total + e.refund;
      });
    }
    else if(type == 'bb')
    {
      this.lists.forEach(e => {
        total = total + e.buyback;
      });
    }
    else if(type == 'disc')
    {
      this.lists.forEach(e => {
        total = total + e.pricingDisc;
      });
    }
    else if(type == 'production')
    {
      this.lists.forEach(e => {
        total = total + e.production;
      });
    }
    else if(type == 'invTy')
    {
      this.lists.forEach(e => {
        total = total + e.invTy;
      });
    }
    else if(type == 'invLy')
    {
      this.lists.forEach(e => {
        total = total + e.invLy;
      });
    }
    else if(type == 'ly')
    {
      this.lists.forEach(e => {
        total = total + e.ly;
      });
    }
    else if(type == 'hrs')
    {
      this.lists.forEach(e => {
        total = total + e.totalHrs;
      });
    }

    return total;
  }
  
  get  getTotalActual()
  {
    let total = 0;

    this.lists.forEach(e => {
      total = total + e.totalActual;
    });
  
    return total;
  }

  get getTotalAvgSales() 
  {
    let total = 0;
    const qty = this.getSalesAmt('invTy');
    if(qty > 0)
    {
      total =  this.getTotalActual/qty;
    }
    return total;
  }

  combinedMagic(totalSales,hrs)
  {
    let total = 0;

    if(hrs > 0)
    {
      total = totalSales / hrs;
    }

    return total;
  }

  get totalCombinedMagic()
  {
    let total = 0;
    const h = this.getSalesAmt('hrs');

    if(h > 0)
    {
      total = this.getTotalActual / h;
    }

    return  total;
  }
}
  
</script>