
import { Options, mixins } from "vue-class-component";
import StoreReports from "../../service/StoreReports";
import UtilityOptions from "../../mixins/UtilityOptions";

interface CouponList
{
  branchCode: string;
  branchName: string;
  cusId: string;
  customer: string;
  customerTel: string;
  date: string;
  discount: number;
  discountId: string;
  discountMethod: string;
  discountName: string;
  employeeId: string;
  employeeName: string;
  id: string;
  timeEntrance: string;
  totalBill: number
  totalQty: number
}
@Options({
  components: {},
})

export default class CouponReport extends mixins(UtilityOptions) {
  private lists: CouponList [] = [];
  private reportService;
  private resultTitle = "";
  private productDialog = false;
  private loading = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Report", to: "report" },
    { label: "Coupon Report" },
  ];

  private searchAssociate = {
    id: "",
    dateFrom: "",
    dateTo: "",
    filter: "None",
    filterBranch: 0,
    empFilter: 0,
  };

  private filterDates = [];
  private dialogTitle;
  private submitted = false;
  private filterBranch = [];

  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.reportService = new StoreReports();
  }
  
   //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.storeList();
    this.loadList();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {       
    this.searchAssociate = {
        id: "",
        dateFrom: "",
        dateTo: "",
        filterBranch: 0,
        empFilter: 0,
        filter: "None",
    };
    this.submitted = false;
    this.dialogTitle = "Filter invoices";
    this.productDialog = true;
  }

  //USED TO LOAD SEARCH DIALOG BOX DATA
  storeList() {
    this.reportService.storeList().then((res) => {
      const data = this.camelizeKeys(res);
      this.filterBranch = data.branchesList;
      this.filterDates = data.filterList;
    });
  }
 
  // USED TO GET SEARCHED ASSOCIATE
  loadList() {
    this.loading = true;
    this.reportService.couponInvoices(this.searchAssociate).then((res) => {
        const data = this.camelizeKeys(res);
        this.resultTitle = data.resultTitle;
        const i = data.invoices;
        const s = data.services;
        const l  = [...i,...s];
        l.sort((a, b) => a.id.localeCompare(b.id));
        this.lists = l;
        this.loading = false;
      });
    this.productDialog = false;
  }
}
