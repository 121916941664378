
import { Options, mixins } from "vue-class-component";
import StoreReports from "../../service/StoreReports";
import UtilityOptions from "../../mixins/UtilityOptions";
import moment from "moment";

interface ItemList {
  ctr        : number; 		
  id         : string; 		
  totalBill : number;
  paid       : number; 		
  cusId     : string; 	
  method     : string; 	
  status     : string; 	
}

@Options({
  components: {},
})

export default class DailySalesReport extends mixins(UtilityOptions) {
  private lists:ItemList []  = [];
  private reportService;
  private counter = 0;
  private resultTitle = "";
  private productDialog = false;
  private loading = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Report", to: "report" },
    { label: "Daily sales report" },
  ];

  private searchAssociate = {
    id: "",
    dateFrom: "",
    dateTo: "",
    filter: "today",
    filterBranch: 0,
  };

  private filterDates = [];
  private dialogTitle;
  private filterBranch:any = [];

  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.reportService = new StoreReports();
  }
  
   //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.storeList();
    this.loadList();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {       
    this.dialogTitle = "Filter Transaction";
    this.productDialog = true;
  }

  //USED TO LOAD SEARCH DIALOG BOX DATA
  storeList() {
    this.reportService.storeList().then((res) => {
      const data = this.camelizeKeys(res);
      this.filterBranch = data.branchesList;
      this.filterDates = data.filterList;
    });
  }
 
  // USED TO GET SEARCHED ASSOCIATE
  loadList() {
    this.loading         = true;
    this.reportService.dailySales(this.searchAssociate).then((res) => {
      const data       = this.camelizeKeys(res);
      this.resultTitle = data.resultTitle;
      this.lists       = data.record;
      console.log(this.lists);
      this.loading     = false;
    });
    this.productDialog   = false;
  }

  getBalanceAmt(balance)
  {
    balance = Number(balance);

    if(balance <= 0.02)
    {
      balance = 0;
    }

    return this.formatAmount(balance);
  }


  get countInvoice()
  {
      return this.lists.length;
  }

  get totalRefund()
  {
    let total = 0;

    this.lists.forEach(e => {

      if(e.status == 'REFUND')
      {
        total = total + e.totalBill;
      }
      
    });

    return total;
  }
  
  get totalInvoice()
  {
    let total = 0;

    this.lists.forEach(e => {

      if(e.status != 'REFUND' && e.status != 'BB')
      {
        total = total + e.totalBill;
      }
      
    });

    return total;
  }
  
  get totalBB()
  {
    let total = 0;

    this.lists.forEach(e => {

      if(e.status == 'BB')
      {
        total = total + e.totalBill;
      }
      
    });

    return total;
  }

  get getDateTime() {
    return (
      "Printed at " +
      moment().format("DD-MMM-YYYY") +
      " / " +
      moment().format("hh:mm A")
    );
  }
}
