<template>
  <section>
    <div class="app-container">
      <Toolbar>
        <template v-slot:left>
          <Breadcrumb
            :home="home"
            :model="items"
            class="p-menuitem-text p-p-1"
          />
        </template>

        <template v-slot:right>
          <div class="p-mx-2">
            <Button
              icon="pi pi-search"
              class="p-button-success px-4"
              @click="openDialog"
            />
          </div>
          <div class="">
            <Button
              icon="pi pi-print"
              class="p-button-primary px-4"
              @click="openDialog"
            />
          </div>
        </template>
      </Toolbar>
      <div class="m-2 mt-4 mb-4 p-text-center">
          <h5>Cash Summary Report</h5>
          <p>{{resultTitle}} <br />
            <i> {{ getDateTime }} </i>
          </p>
      </div>
      <div class="p-mt-2 p-p-2 ">
        <div class="p-grid">
          <div class="p-col">
            <h5>Sales For All Schedule</h5>
          </div>
          <div class="p-col">
            <h5>Receipt For All Schedule</h5>
          </div>
          <div class="p-col">
            <h5>Receipt Refunds Paid out Total</h5>
          </div>
          <div class="p-col">
            <h5>Receipt Refunds Paid out Sales</h5>
          </div>
        </div>
      </div>
      <Dialog
        v-model:visible="productDialog"
        :style="{ width: '50vw' }"
        :maximizable="true"
        position="top"
        class="p-fluid"
      >
        <template #header>
          <h5 class="p-dialog-titlebar p-dialog-titlebar-icon">
           <i class="pi pi-search" style="font-size:1.2rem;"></i> {{ dialogTitle }}
          </h5>
        </template>
        <div class="p-grid">
            <div class="p-col">
              <label for="weekDate">Week Date</label>
              <input type="date" id="weekDate"  v-model="searchAssociate.weekDate" class="form-control">
            </div>
        </div>
        <div class="p-field p-pt-5">
            <label for="filterStore">Filter Branch</label>
            <Dropdown
                v-model="searchAssociate.filterBranch"
                :options="filterBranch"
                :filter="true"
                optionLabel="mall"
                optionValue="code"
            />
            
        </div>
        <template #footer>
          <Button
            type="submit"
            label="Search"
            icon="pi pi-search"
            class="p-button-primary"
            @click="loadList"
          />
        </template>
      </Dialog>
    </div>
  </section>
</template>
<script lang="ts">
import { Options, mixins } from "vue-class-component";
import StoreReports from "../../service/StoreReports";
import UtilityOptions from "../../mixins/UtilityOptions";
import moment from "moment";

@Options({
  components: {},
})

export default class CashSummaryReport extends mixins(UtilityOptions) {
  private reportService;
  private resultTitle = "";
  private productDialog = false;
  private loading = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Report", to: "report" },
    { label: "Cash Summary Report" },
  ];

  private searchAssociate = {
    id: "",
    weekDate: "",
    filterBranch: 0,
  };

  private filterDates = [];
  private weekDates ={};
  private dialogTitle;
  private filterBranch:any = [];

  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.reportService = new StoreReports();
  }
  
   //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.storeList();
    this.loadList();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {       
    this.dialogTitle = "Filter Cash";
    this.productDialog = true;
  }

  //USED TO LOAD SEARCH DIALOG BOX DATA
  storeList() {
    this.reportService.storeList().then((res) => {
      const data = this.camelizeKeys(res);
      this.filterBranch = data.branchesList;
      this.filterDates = data.filterList;
    });
  }
 
  // USED TO GET SEARCHED ASSOCIATE
  loadList() {
    this.loading         = true;
    this.reportService.cashSummary(this.searchAssociate).then((res) => {
      const data         = this.camelizeKeys(res);
      this.resultTitle   = data.resultTitle;
      this.weekDates     = data.weekDates;
      this.loading       = false;
    });
    this.productDialog   = false;
  }

  get getDateTime() {
    return (
      "Printed at " +
      moment().format("DD-MMM-YYYY") +
      " / " +
      moment().format("hh:mm A")
    );
  }
}
</script>

<style scoped>
.br
{
  border-top:1px solid #ccc;
  border-right:1px solid #ccc;
}
.bb
{
  border-bottom:1px solid #ccc;
}
</style>