
import { Options, mixins } from "vue-class-component";
import StoreReports from "../../service/StoreReports";
import UtilityOptions from "../../mixins/UtilityOptions";

@Options({
  components: {},
})
export default class AssociateReport extends mixins(UtilityOptions) {
  private lists = [];
  private reportService;
  private resultTitle = "";
  private productDialog = false;
  private loading = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Report", to: "report" },
    { label: "Associate Report" },
  ];

  private searchAssociate = {
    id: "",
    dateFrom: "",
    dateTo: "",
    filter: "None",
    filterBranch: 0,
    empFilter: "current",
  };

  private filterDates = [];

  private dialogTitle;
  private submitted = false;
  private employeeFilter = [
    {  name: "Only Current Store Associates", value:"current" },
    {  name: "Only Current & Associated Store Associates",value: 'associated' },
    {  name: "All Associates",value: 'all' },
  ];

  private filterBranch = [];

  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.reportService = new StoreReports();
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.storeList();
    this.loadList();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.submitted = false;
    this.dialogTitle = "Filter Employee Measurable Report";
    this.productDialog = true;
  }

  //USED TO LOAD SEARCH DIALOG BOX DATA
  storeList() {
    this.reportService.storeList().then((res) => {
      const data = this.camelizeKeys(res);
      this.filterBranch = data.branchesList;
      this.filterDates = data.filterList;
    });
  }

  // USED TO GET SEARCHED ASSOCIATE
  loadList() {
    this.loading = true;
    this.reportService.associateReport(this.searchAssociate).then((res) => {
      const data = this.camelizeKeys(res);
      this.resultTitle = data.resultTitle;
      this.lists = data.records;
      this.loading = false;
    });
    this.productDialog = false;
  }
}
