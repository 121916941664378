<template>
  <section>
    <div class="app-container">
      <Toolbar>
        <template v-slot:left>
          <Breadcrumb
            :home="home"
            :model="items"
            class="p-menuitem-text p-p-1"
          />
        </template>

        <template v-slot:right>
          <div class="p-mx-2">
            <Button
              icon="pi pi-search"
              class="p-button-success px-4"
              @click="openDialog"
            />
          </div>
          <div class="">
            <Button
              icon="pi pi-print"
              class="p-button-primary px-4"
              @click="openDialog"
            />
          </div>
        </template>
      </Toolbar>
      <div class="m-2 mt-4 mb-4 p-text-center">
          <h5>Buyback Report</h5>
          <p>{{resultTitle}}</p>
      </div>
      <div class="p-mt-2">
        <DataTable
          :value="lists"
          :lazy="true"
          :scrollable="true"
          class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        >
          <template #empty>
            <div class="p-text-center p-p-3">No records found</div>
          </template>
          <Column header="Action" v-if="isAdmin == 'HQ'">
             <template #body="slotProps">
               <Button v-if="slotProps.data.adminApprove == 'app'" class="p-button-success" @click="changeBBStatus(slotProps.data.id,'not app')" label="Not Approve" />
               <Button v-if="slotProps.data.adminApprove == 'not app'" class="p-button-primary" @click="changeBBStatus(slotProps.data.id,'app')" label="Approve" />
            </template>
          </Column>
          <Column header="Admin Approve">
             <template #body="slotProps">
              {{ translateStatus(slotProps.data.adminApprove) }}
            </template>
          </Column>
          <Column header="Store Name" headerStyle="width: 120rem">
            <template #body="slotProps">
              {{ slotProps.data.storeName }} ({{ slotProps.data.branchId }})
            </template>
          </Column>
          <Column field="invId" header="Invoice No"></Column>
          <Column header="Date/Time" >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.date) }} / {{ formatTime(slotProps.data.time) }}
            </template>
          </Column>
          <Column header="Customer Name" headerStyle="width: 120rem">
            <template #body="slotProps">
              {{ slotProps.data.customerName }} ({{ slotProps.data.customerId }})
            </template>
          </Column>
          <Column  header="Associate Name" headerStyle="width: 120rem">
            <template #body="slotProps">
              {{ slotProps.data.employeeName }} ({{ slotProps.data.employeeId }})
            </template>
          </Column>
          <Column header="Cheque Need">
             <template #body="slotProps">
              {{ slotProps.data.chequeNeedCustomer }}
            </template>
          </Column>
          <Column header="Store Payout">
             <template #body="slotProps">
              {{ slotProps.data.payoutInStore }}
            </template>
          </Column>
          <Column header="Total Garments">
             <template #body="slotProps">
             $ {{ formatAmount(slotProps.data.totalOfGarments) }}
            </template>
          </Column>
          <Column header="Refund Alteration">
             <template #body="slotProps">
              {{ slotProps.data.refundAlterationCus }}
            </template>
          </Column>
          <Column header="Fill in Price">
             <template #body="slotProps">
             $ {{ formatAmount(slotProps.data.fillInPrice) }}
            </template>
          </Column>
          <Column header="Total to Pay">
             <template #body="slotProps">
             $ {{ formatAmount(slotProps.data.totalToPaid) }}
            </template>
          </Column>
          <Column header="Reason">
             <template #body="slotProps">
              {{ slotProps.data.reason }}
            </template>
          </Column>
          <Column header="Garment Description">
             <template #body="slotProps">
              {{ slotProps.data.garmentDescription }}
            </template>
          </Column>
          <Column header="Receipt In Mail">
             <template #body="slotProps">
              {{ slotProps.data.receiptInMail }}
            </template>
          </Column>
          <Column header="Garment In Mail">
             <template #body="slotProps">
              {{ slotProps.data.garmentInMail }}
            </template>
          </Column>
          <Column header="Fit in Expert Name">
             <template #body="slotProps">
              {{ slotProps.data.fitExpertName }}
            </template>
          </Column>
          <Column header="Sew Expert Name">
             <template #body="slotProps">
              {{ slotProps.data.sewExpertName }}
            </template>
          </Column>
          <Column header="Associate Responsible">
             <template #body="slotProps">
              {{ slotProps.data.associateResponsible }}
            </template>
          </Column>
        </DataTable>
      </div>

      <Dialog
        v-model:visible="productDialog"
        :style="{ width: '50vw' }"
        :maximizable="true"
        position="top"
        class="p-fluid"
      >
        <template #header>
          <h5 class="p-dialog-titlebar p-dialog-titlebar-icon">
           <i class="pi pi-search" style="font-size:1.2rem;"></i> {{ dialogTitle }}
          </h5>
        </template>
        <div class="p-field">
            <label for="filterStore">Filter Date:</label>
            <Dropdown
              v-model="searchAssociate.filter"
              :options="filterDates"
              :filter="true"
              optionLabel="key"
              optionValue="value"
            />
        </div>
        <h5>OR</h5>
        <div class="p-field">
          <label for="from">Date From:</label>
          <input type="date" id="from"  v-model="searchAssociate.dateFrom" class="form-control">
        </div>
        <div class="p-field">
          <label for="to">Date To:</label>
          <input type="date" id="to"  v-model="searchAssociate.dateTo" class="form-control">
        </div>
        <div class="p-field p-pt-5">
            <label for="filterStore">Filter Branch:</label>
            <Dropdown
                v-model="searchAssociate.filterBranch"
                :options="filterBranch"
                :filter="true"
                optionLabel="mall"
                optionValue="code"
            />
            
        </div>
        <template #footer>
          <Button
            type="submit"
            label="Search"
            icon="pi pi-search"
            class="p-button-primary"
            @click="loadList"
          />
        </template>
      </Dialog>
    </div>
  </section>
</template>
<script lang="ts">
import { Options, mixins } from "vue-class-component";
import StoreReports from "../../service/StoreReports";
import UtilityOptions from "../../mixins/UtilityOptions";
import Toaster from "../../helpers/Toaster";

@Options({
  components: {},
})

export default class BuybackReport extends mixins(UtilityOptions) {
  private lists  = [];
  private reportService;
  private resultTitle = "";
  private isAdmin = "";
  private productDialog = false;
  private loading = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Report", to: "report" },
    { label: "Buyback Report" },
  ];

  private searchAssociate = {
    id: "",
    dateFrom: "",
    dateTo: "",
    filter: "None",
    filterBranch: 0,
    empFilter: 0,
  };

  private filterDates = [];
  private dialogTitle;
  private submitted = false;
  private filterBranch = [];
   private toast;

  //CALLING WHENEVER COMPONENT LOADS
  created() {
     this.toast = new Toaster();
    this.reportService = new StoreReports();
  }
  
   //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.storeList();
    this.loadList();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {       
    this.searchAssociate = {
        id: "",
        dateFrom: "",
        dateTo: "",
        filterBranch: 0,
        empFilter: 0,
        filter: "None",
    };
    this.submitted = false;
    this.dialogTitle = "Filter invoices";
    this.productDialog = true;
  }

  //USED TO LOAD SEARCH DIALOG BOX DATA
  storeList() {
    this.reportService.storeList().then((res) => {
      const data = this.camelizeKeys(res);
      this.filterBranch = data.branchesList;
      this.filterDates = data.filterList;
    });
  }
 
  // USED TO GET SEARCHED ASSOCIATE
  loadList() {
    this.loading = true;
    this.reportService.buybackInvoices(this.searchAssociate).then((res) => {
        const data = this.camelizeKeys(res);
        this.isAdmin = data.isAdmin;
        this.resultTitle = data.resultTitle;
        this.lists = data.record;
        this.loading = false;
      });
    this.productDialog = false;
  }

  changeBBStatus(id,status)
  {
    this.loading = true;
    this.reportService.buybackChangeStatus(id,status).then((res) => {
        this.toast.handleResponse(res);
        this.loading = false;
        this.loadList();
      });
  }

  translateStatus(s)
  {
    let t = '';

    if(s == 'app')
    {
      t = 'Approved';
    }
    else
    {
      t = 'Not Approved';
    }

    return t;
  }
}
</script>