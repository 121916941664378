
import { Options, mixins } from "vue-class-component";
import StoreReports from "../../service/StoreReports";
import UtilityOptions from "../../mixins/UtilityOptions";
import Toaster from "../../helpers/Toaster";

@Options({
  components: {},
})

export default class BuybackReport extends mixins(UtilityOptions) {
  private lists  = [];
  private reportService;
  private resultTitle = "";
  private isAdmin = "";
  private productDialog = false;
  private loading = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Report", to: "report" },
    { label: "Buyback Report" },
  ];

  private searchAssociate = {
    id: "",
    dateFrom: "",
    dateTo: "",
    filter: "None",
    filterBranch: 0,
    empFilter: 0,
  };

  private filterDates = [];
  private dialogTitle;
  private submitted = false;
  private filterBranch = [];
   private toast;

  //CALLING WHENEVER COMPONENT LOADS
  created() {
     this.toast = new Toaster();
    this.reportService = new StoreReports();
  }
  
   //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.storeList();
    this.loadList();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {       
    this.searchAssociate = {
        id: "",
        dateFrom: "",
        dateTo: "",
        filterBranch: 0,
        empFilter: 0,
        filter: "None",
    };
    this.submitted = false;
    this.dialogTitle = "Filter invoices";
    this.productDialog = true;
  }

  //USED TO LOAD SEARCH DIALOG BOX DATA
  storeList() {
    this.reportService.storeList().then((res) => {
      const data = this.camelizeKeys(res);
      this.filterBranch = data.branchesList;
      this.filterDates = data.filterList;
    });
  }
 
  // USED TO GET SEARCHED ASSOCIATE
  loadList() {
    this.loading = true;
    this.reportService.buybackInvoices(this.searchAssociate).then((res) => {
        const data = this.camelizeKeys(res);
        this.isAdmin = data.isAdmin;
        this.resultTitle = data.resultTitle;
        this.lists = data.record;
        this.loading = false;
      });
    this.productDialog = false;
  }

  changeBBStatus(id,status)
  {
    this.loading = true;
    this.reportService.buybackChangeStatus(id,status).then((res) => {
        this.toast.handleResponse(res);
        this.loading = false;
        this.loadList();
      });
  }

  translateStatus(s)
  {
    let t = '';

    if(s == 'app')
    {
      t = 'Approved';
    }
    else
    {
      t = 'Not Approved';
    }

    return t;
  }
}
