
import { Options, mixins } from "vue-class-component";
import StoreReports from "../../service/StoreReports";
import UtilityOptions from "../../mixins/UtilityOptions";

interface IRegions {
  branchId: string;
  country: string;
  createdDate: string;
  provinceName: string;
  regionCode: string;
  regionId: string;
  regionName: string;
}

interface FlastReport {
  branchCode: string;
  branchName: string;
  buyback: number;
  corporateActual: number;
  corporatePer: number;
  diffPer: number;
  dryCleaningActual: number;
  dryCleaningPer: number;
  gpsPer: number;
  gpsScore: number;
  invLy: number;
  invPlusMinus: number;
  invTy: number;
  ly: number;
  lyPer: number;
  totalHrs: number;
  production: number;
  pricingAvgSales: number;
  pricingDisc: number;
  productActual: number;
  productPer: number;
  redo: number;
  referralActual: number;
  referralPer: number;
  refund: number;
  salesRegularActual: number;
  salesRegularPer: number;
  serviceDate: string;
  totalActual: number;
  turnaround3Hrs: number;
  turnaround12Hrs: number;
  turnaroundOnTime: number;
  yearPlanned: [];
}


@Options({
  components: {},
})

export default class FlashReport extends mixins(UtilityOptions) {

  private lists: FlastReport [] = [];
  private reportService;
  private resultTitle = "";
  private weekStart = 0;
  private weekEnd = 0;
  private productDialog = false;
  private loading = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Report", to: "report" },
    { label: "Flash Report" },
  ];

  private filterDates = [
    {
      key: 'This Week',
      value: 'this_week'
    },
    {
      key: 'Month to Date',
      value: 'month_to_date'
    },
    {
      key: 'Year to Date',
      value: 'year_to_date'
    }
  ];
  private dialogTitle;
  private submitted = false;
  private filterRegions: IRegions [] = [];

  private searchAssociate = {
    id: "",
    dateFrom: "",
    dateTo: "",
    filter: "this_week",
    filterRegion: '',
  };

  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.reportService = new StoreReports();
  }
  
   //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.storeList();
    this.loadList();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {       
    this.submitted = false;
    this.dialogTitle = "Filter invoices";
    this.productDialog = true;
  }

  //USED TO LOAD SEARCH DIALOG BOX DATA
  storeList() {
    this.reportService.storeList().then((res) => {
      const data = this.camelizeKeys(res);
      this.filterRegions = data.regionList;
      this.searchAssociate.filterRegion = this.getDefaultRegion;
    });
  }
 
  // USED TO GET SEARCHED ASSOCIATE
  loadList() {
    this.loading = true;
    this.reportService.flashReport(this.searchAssociate).then((res) => {
        const data = this.camelizeKeys(res);
        this.resultTitle = data.resultTitle;
        this.lists = data.records;
        this.weekStart = data.weekStart;
        this.weekEnd   = data.weekEnd;
        this.loading = false;
      });
    this.productDialog = false;
  }

  get getActiveRegion()
  {
    let regionName = '';
    const r = this.filterRegions.filter(e => e.regionId == this.searchAssociate.filterRegion);

    if(r.length > 0)
    {
      regionName = r[0].regionName+' ('+r[0].regionCode+')';
    }

    return regionName;
  }

  get getDefaultRegion()
  {
    let id = '';
    if(this.filterRegions.length > 0)
    {
      id = this.filterRegions[0].regionId;
    }
    return id;
  }

  get flashReport()
  {
    // const s1 = Number(this.weekStart);
    // const s2 = Number(this.weekEnd);

    // const a = this.lists.forEach(e => {
    // let totalPlanned = 0;

      
      
    //   if(e.yearPlanned.length >= 54)
    //   {
    //     for (let i = 1; i < 54; i++) {
    //         if(i >= s1 && i <= s2)
    //         {
    //           totalPlanned = totalPlanned + Number(e.yearPlanned[i]); 
    //         } 
    //     }
    //   }
      


    //   // for(let i = s1; i <= s2; i++)
    //   // {
    //   //   const n = 'week'+i;
    //   //   totalPlanned = totalPlanned + e.yearPlanned.'week';
    //   //   console.log(e);
    //   //   console.log(i);    
    //   // }
        
    // });
    
    return this.lists;
  }

  getLyPercentage(actual,ly)
  {
    let p = 0;

    if(ly > 0)
    {
      p = ((actual/ly) * 100 ) - 100;  
    }
    return p;
  }

  getPercentage(type)
  {
    let p = 0;

    const total = this.getSalesAmt(type);
    const actual = this.getTotalActual;

    if(total > 0)
    {
      p = (Number(total/actual)) * 100;
    }

    return p;
  }
  
  getSalesAmt(type)
  {
    let total = 0;

    if(type == 'Regular')
    {
      this.lists.forEach(e => {
        total = total + e.salesRegularActual;
      });
    }
    else if(type == 'Referral')
    {
      this.lists.forEach(e => {
        total = total + e.referralActual;
      });
    }
    else if(type == 'Corporate')
    {
      this.lists.forEach(e => {
        total = total + e.corporateActual;
      });
    }
    else if(type == 'Product')
    {
      this.lists.forEach(e => {
        total = total + e.productActual;
      });
    }
    else if(type == 'Dry_clean')
    {
      this.lists.forEach(e => {
        total = total + e.dryCleaningActual;
      });
    }
    else if(type == '3hrs')
    {
      this.lists.forEach(e => {
        total = total + e.turnaround3Hrs;
      });
    }
    else if(type == '12hrs')
    {
      this.lists.forEach(e => {
        total = total + e.turnaround12Hrs;
      });
    }
    else if(type == 'OnTime')
    {
      this.lists.forEach(e => {
        total = total + e.turnaroundOnTime;
      });
    }
    else if(type == 'redo')
    {
      this.lists.forEach(e => {
        total = total + e.redo;
      });
    }
    else if(type == 'refund')
    {
      this.lists.forEach(e => {
        total = total + e.refund;
      });
    }
    else if(type == 'bb')
    {
      this.lists.forEach(e => {
        total = total + e.buyback;
      });
    }
    else if(type == 'disc')
    {
      this.lists.forEach(e => {
        total = total + e.pricingDisc;
      });
    }
    else if(type == 'production')
    {
      this.lists.forEach(e => {
        total = total + e.production;
      });
    }
    else if(type == 'invTy')
    {
      this.lists.forEach(e => {
        total = total + e.invTy;
      });
    }
    else if(type == 'invLy')
    {
      this.lists.forEach(e => {
        total = total + e.invLy;
      });
    }
    else if(type == 'ly')
    {
      this.lists.forEach(e => {
        total = total + e.ly;
      });
    }
    else if(type == 'hrs')
    {
      this.lists.forEach(e => {
        total = total + e.totalHrs;
      });
    }

    return total;
  }
  
  get  getTotalActual()
  {
    let total = 0;

    this.lists.forEach(e => {
      total = total + e.totalActual;
    });
  
    return total;
  }

  get getTotalAvgSales() 
  {
    let total = 0;
    const qty = this.getSalesAmt('invTy');
    if(qty > 0)
    {
      total =  this.getTotalActual/qty;
    }
    return total;
  }

  combinedMagic(totalSales,hrs)
  {
    let total = 0;

    if(hrs > 0)
    {
      total = totalSales / hrs;
    }

    return total;
  }

  get totalCombinedMagic()
  {
    let total = 0;
    const h = this.getSalesAmt('hrs');

    if(h > 0)
    {
      total = this.getTotalActual / h;
    }

    return  total;
  }
}
  
